/* src/App.css */
@font-face {
  font-family: 'Contra';
  src: url('./fonts/Contra.ttf') format('truetype');
}

.App {
  overflow: hidden;
  position: relative;
  height: 100vh;
  width: 100vw;
  font-family: 'Contra', 'Press Start 2P', cursive;
  font-size: .8em;
}

.contra-loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  max-width: 1280px; /* Set max width to 1280px */
  color: rgb(230, 230, 230);
  text-shadow: 2px 2px 0 #000;
  background-color: black;
  position: absolute;
  top: 0; /* Adjust top for initial positioning */
  left: 50%;
  transform: translateX(50%); /* Start from the right off-screen */
  animation: slide-in 4s linear forwards; /* Apply smooth slide-in animation */
  text-align: center;
  font-size: .8em;
}

@keyframes slide-in {
  0% {
    transform: translateX(100%); /* Start from the right side, off-screen */
  }
  100% {
    transform: translateX(-50%); /* End in the center */
  }
}

.contra-loading-screen.animate {
  right: 0%;
}

.power-on-button {
  font-family: 'Contra', 'Press Start 2P', cursive;
  font-size: 16px;
  padding: 5px 10px;
  color: red;
  background-color: gray;
  border: 2px solid rgb(110, 110, 110);
  border-radius: 5px;
  cursor: pointer;
  width: 80px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.power-on-button:hover {
  background-color: #333;
}

.contra-loading-screen h1 {
  font-size: 24px;
  margin-bottom: 10px;
  width: 80%;
  text-align: center;
}

.contra-loading-screen p {
  font-size: 16px;
  margin-bottom: 20px;
  width: 80%;
  text-align: center;
}

.player-select {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 80%;
}

.player-select-secret {
  display: flex;
  justify-content: center;
  padding-top: 15px;
  gap: 10px;
  width: 100%;
}

.player {
  font-size: 12px;
  padding: 5px 10px;
  border: 2px solid white;
  border-radius: 5px;
  width: 100px;
  text-align: center;
}

a:link,
a:visited,
a:hover,
a:active {
  color: rgb(230, 230, 230);
  text-decoration: none;
}

.flicker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  animation: flicker 0.5s linear;
}

@keyframes flicker {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.row {
  display: flex;
  width: 80%;
}

.column {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -100px;
}

.column-header {
  margin-top: 100px;
  margin-bottom: 20px;
}

.link-row {
  display: flex;
  align-items: center;
}

.arrow-container {
  width: 24px; /* Fixed width */
  display: inline-block;
  text-align: right;
  margin-right: 10px;
}

.arrow-show,
.arrow-hide {
  font-size: 24px;
  visibility: hidden;
}

.arrow-show {
  visibility: visible;
}

.link-selector {
  position: relative;
}

@media (min-width: 768px) {
  .contra-loading-screen {
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
  }
  .contra-loading-screen h1 {
    font-size: 48px;
    margin-bottom: 20px;
  }

  .contra-loading-screen p {
    font-size: 24px;
    margin-bottom: 40px;
  }

  .player-select {
    gap: 20px;
    width: 300px;
  }

  .player {
    font-size: 16px;
    padding: 10px 20px;
    width: 150px;
  }

  .power-on-button {
    font-size: 24px;
    padding: 10px 20px;
    width: 100px;
  }

  .row {
    display: flex;
    width: 50%;
  }
}